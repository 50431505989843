//import PropTypes from "prop-types";
import { useState } from "react";
import { useAppState } from "../../hooks";

/**
 * Displays the current version of DIVER - and detail versions on click
 */
export const VersionLink = () => {
    
    const [ displayDetail, setDisplayDetail ] = useState(false);
    const { versionInfo } = useAppState();
    
    const toggleDetails = () => {
        setDisplayDetail(!displayDetail);
    }
    
    return (
        <div className='version-link'>
            <p className="version-link-main" onClick={toggleDetails}>DIVER version {versionInfo.diverapp}</p>
            {displayDetail && 
                <p className='version-link-detail'>diverweb {versionInfo.diverweb}, diverRPC {versionInfo.diverRPC}, db_procs {versionInfo.db_procs}, db_edit {versionInfo.db_edit}</p>
            }
        </div>
    )
}


/*VersionLink.propTypes = {
    versionInfo: PropTypes.object,
}*/
