import { createContext, useState, useEffect, useCallback } from 'react';
import { toast } from "react-toastify";

import api from "../utils/api";

// yes they say not to do this but all the other methods are equally evil
//import { version } from '../../package.json';


// Space for sharing a limited amount of app-wide state.

export const AppStateContext = createContext({
    project: null,
    setProject: () => {},
    userLoggedIn: 'false',
    userPrivs: [],
    clearLoggedInUser: () => {},
    checkForUserInfo: () => {},
    errorCheckText: () => {},
    versionInfo: {
        diverapp: process.env.REACT_APP_VERSION,
        diverweb: process.env.REACT_APP_WEBAPP_VERSION,
        diverRPC: '...',
        db_procs: '...',
        db_edit: '...'
        },
});

export const AppStateProvider = ({ children } ) => {
    const [ project, setProject ] = useState(null);
    const [ userPrivs, setUserPrivs ] = useState([]);
    const [ userLoggedIn, setUserLoggedIn ] = useState(false);
    const [ initialVerify, setInitialVerify ] = useState(true);
    const [ versionInfo, setVersionInfo ] = useState({
        diverapp: process.env.REACT_APP_VERSION,
        diverweb: process.env.REACT_APP_WEBAPP_VERSION,
        diverRPC: '...',
        db_procs: '...',
        db_edit: '...'
        });
    
    const clearLoggedInUser = useCallback(() => {
        if (window.diverwebUsingExternalAuthForm) {
            window.dispatchEvent(new Event("diverwebNoUserLoggedIn"));
        }
        setUserLoggedIn(false);
        setUserPrivs([]);
        setProject(null);
    }, [setUserLoggedIn, setUserPrivs, setProject])
    
    const errorCheckText = useCallback((err, extraText) => {
        if (err && err.response) {
            if (err.response.status === 401) {
                clearLoggedInUser()
            }
            if (err.response.statusText) {
                if (err.response.data.message) {
                    return `${err.response.statusText}: ${err.response.data.message}. ${extraText}`;
                } else {
                    return `${err.response.statusText}. ${extraText}`;
                }
            }
        }
        return extraText
        
    }, [clearLoggedInUser]);
    
    const checkForUserInfo = useCallback(() => {
        api.getUserInfo()
        .then((res) => {
            if (res.data.current_user !== null) {
                if (window.diverwebUsingExternalAuthForm) {
                    window.dispatchEvent(new Event("diverwebUserIsLoggedIn"));
                }
                setUserLoggedIn(true);
                setUserPrivs(JSON.parse(res.data.current_user.collections));
                setProject(res.data.current_user);
            } else {
                clearLoggedInUser();
            }
            setVersionInfo(Object.assign({diverapp: process.env.REACT_APP_VERSION, diverweb: process.env.REACT_APP_WEBAPP_VERSION}, res.data.versions));
        })
        .catch(err => {
            toast.error(errorCheckText(err, 'Unable to get backend app info.'))
            clearLoggedInUser();
            setVersionInfo({
                diverapp: process.env.REACT_APP_VERSION,
                diverweb: process.env.REACT_APP_WEBAPP_VERSION,
                diverRPC: '...',
                db_procs: '...',
                db_edit: '...'
                });
        })
        
    }, [setUserLoggedIn, setUserPrivs, setProject, clearLoggedInUser, setVersionInfo, errorCheckText])
    
    // Initial state retrieval attempt.
    useEffect(() => {
        if (initialVerify) {
            setInitialVerify(false);
            checkForUserInfo();
        }
    }, [initialVerify, setInitialVerify, checkForUserInfo])
    
    // User info check hook for when we're doing authentication outside of this
    // application (by setting a shared cookie).
    useEffect(() => {
        if (window.diverwebUsingExternalAuthForm) {
            window.addEventListener("diverwebReverifyLoginStatus",
                    checkForUserInfo);
            
            return () => {
                window.removeEventListener("diverwebReverifyLoginStatus",
                        checkForUserInfo);
            }
        }
    }, [checkForUserInfo])
    
    const contextValue = {
        project,
        setProject,
        userLoggedIn,
        userPrivs,
        clearLoggedInUser,
        checkForUserInfo,
        versionInfo,
        errorCheckText,
    };

    return (
        <AppStateContext.Provider value={contextValue}>
            {children}
        </AppStateContext.Provider>
    )
}
