import { Link } from "react-router-dom";
//import PropTypes from "prop-types";
import { ConditionalWrapper } from "../Utilities";
import { condClass } from "../../utils/conditionalClass";


/**
 * Renders a button component;
 * Can be used to render either an anchor/link or an actual button
 */
export const Button = ({
    // adds the btn--action class
    action,
    // child node(s)
    children,
    // class names
    classes,
    // class names
    className,
    // if the button is disabled - separated out so it can't be accidentally set on link
    disabled,
    // click function for button, not link
    onClick,
    // most buttons get "btn" class, unless specified here
    noBtnClass,
    // xs, sm, md, etc
    size,
    // path for link
    to,
    // for button: submit, button, or reset
    type,
    // if text should be caps
    uppercase,
    // Bootstrap variant
    variant,
    // Currently 'loading'
    buttonLoading,
    // Everything else - gets set directly on button/link
    ...rest
}) => {

    const buttonClasses = "" +
        condClass(!noBtnClass, "btn") +
        condClass(variant, 'btn-' + variant) +
        condClass(classes, classes) + 
        condClass(className, className) + // just a convenience in case we put className on a Button instead of classes 
        condClass(size, 'btn-' + size) +
        condClass(action, 'btn--action') +
        condClass(uppercase, 'btn--caps') +
        condClass(disabled, 'disabled') +
        condClass(buttonLoading, 'btn--loading') +
    "";

    return (

        <ConditionalWrapper
            condition={!to}
            wrapper={children => (
                <button 
                    {...rest}
                    type={type ? type : "button"} 
                    className={buttonClasses} 
                    onClick={buttonLoading ? ()=>{} : onClick}
                    disabled={disabled}
                >
                    <span className="btn--text">
                    {children}
                    </span>
                </button>
            )}
            altWrapper={children => (
                <Link 
                    {...rest}
                    to={to}
                    className={buttonClasses}
                >
                    {children}
                </Link>
            )}
        >
            {children}
        </ConditionalWrapper>
        
    )
    
}


/*Button.propTypes = {
    action: PropTypes.bool,
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    noBtnClass: PropTypes.bool,
    size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
    to: PropTypes.string,
    type: PropTypes.oneOf(["button", "reset", "submit"]),
    uppercase: PropTypes.bool,
    variant: PropTypes.oneOf(["primary", "secondary", "danger"]),
}*/
