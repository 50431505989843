
import { AllConstructs, NewMultiVarConstruct, NewDowncodeConstruct, NewTwoVarConstruct } from "../../pages/ClinicalConstructs";
import { AllCohorts, AscertainPedigrees, CreateCompoundCohort, CreateSubsetCohort, /*CohortLogic,*/ ViewCohort } from "../../pages/Cohorts";
import { AllVariables, ViewVariable } from "../../pages/Variables";
import { MyVariableSets, NewVariableSet, ViewVariableSet } from "../../pages/VariableSets";
import { ListProjects, NewProject, ViewProject } from "../../pages/Projects";
import { NewDownload } from "../../pages/Downloads";
import { Home } from "../../pages/Home";

export const ALL_COHORTS = "allCohorts";
export const SUBSET_COHORT = "subsetCohort";
export const COMPOUND_COHORT = "compoundCohort";
export const ASCERTAIN_PEDIGREE = "ascertainPedigree";
// Removing for now per issue #251
//export const COHORT_LOGIC = "cohortLogic";
export const VIEW_COHORT = "viewCohort";
export const MY_COHORTS = "myCohorts";

export const ALL_VARIABLES = "allVariables";
export const EXPLORE_HARMONIZATION = "exploreHarmonization";
export const VIEW_VARIABLE = "viewVariable";

export const NEW_VARIABLE_SET = "newVariableSet";
export const VIEW_VARIABLE_SET = "viewVariableSet";
export const MY_VARIABLE_SETS = "myVariableSets";

export const ALL_CLINICAL_CONSTRUCTS = "allClinicalConstructs";
export const NEW_DOWNCODE = "newDowncode";
export const NEW_CLINICAL_CONSTRUCT = "newMultiVarConstruct";
export const NEW_TWO_VAR_CONSTRUCT = "newTwoVarConstruct";

export const NEW_DOWNLOAD = "newDownload";

export const NEW_PROJECT = "newProject";
export const VIEW_PROJECT = "viewProject";
export const LIST_PROJECTS = "listProjects";

export const HOME = "home";

export const PDF_VIEW = "pdf_view";

export const ROUTES = [
    // PROJECTS
    {
        name: NEW_PROJECT,
        path: "/projects/new",
        component: NewProject,
        helpPage: 4,
    },
    {
        name: VIEW_PROJECT,
        path: "/projects/:id",
        component: ViewProject,
        helpPage: 6,
    },
    {
        name: LIST_PROJECTS,
        path: "/projects",
        component: ListProjects,
        helpPage: 3,
    },
    // COHORTS
    {
        name: ALL_COHORTS,
        path: "/cohorts",
        component: AllCohorts,
        helpPage: 27,
    },
    {
        name: SUBSET_COHORT,
        path: "/cohorts/subset/:id?",
        component: CreateSubsetCohort,
        helpPage: 28,
    },
    {
        name: COMPOUND_COHORT,
        path: "/cohorts/compound/:id",
        component: CreateCompoundCohort,
        helpPage: 32,
    },
    {
        name: ASCERTAIN_PEDIGREE,
        path: "/cohorts/pedigrees/:id?",
        component: AscertainPedigrees,
        helpPage: 36,
    },
    /* Removing for now per issue #251
    {
        name: COHORT_LOGIC,
        path: "/cohorts/logic/:id",
        component: CohortLogic,
        helpPage: 27,
    }, */
    {
        name: VIEW_COHORT,
        path: "/cohorts/:id",
        component: ViewCohort,
        helpPage: 27,
    },
    // VARIABLES
    // Variable Catalog
    {
        name: ALL_VARIABLES, 
        path: "/variables/all",
        component: AllVariables,
        helpPage: 9,
    },
    {
        name: VIEW_VARIABLE,
        path: "/variables/:id",
        component: ViewVariable,
        helpPage: 11,
    },
    // VARIABLE SETS
    {
        name: NEW_VARIABLE_SET,
        path: "/variable-sets/new",
        component: NewVariableSet,
        helpPage: 43,
    },
    {
        name: VIEW_VARIABLE_SET,
        path: "/variable-sets/:id",
        component: ViewVariableSet,
        helpPage: 43,
    },
    {
        name: MY_VARIABLE_SETS,
        path: "/variable-sets",
        component: MyVariableSets,
        helpPage: 43,
    },
    // CLINICAL CONSTRUCTS
    // NOTE: MultiVar is hidden for now due to concerns w/r/t complexity for
    // users, but some folks still use it so it's not completely disabled.
    {
        name: ALL_CLINICAL_CONSTRUCTS,
        path: "/clinical-constructs/all",
        component: AllConstructs,
        helpPage: 16,
    },
    {
        name: NEW_CLINICAL_CONSTRUCT,
        path: "/clinical-constructs/multi",
        component: NewMultiVarConstruct,
        // no helpPage yet as it's undocumented
        helpPage: 1,
    },
    {
        name: NEW_DOWNCODE,
        path: "/clinical-constructs/downcode",
        component: NewDowncodeConstruct,
        helpPage: 16,
    },
    {
        name: NEW_TWO_VAR_CONSTRUCT,
        path: "/clinical-constructs/two-vars",
        component: NewTwoVarConstruct,
        helpPage: 22,
    },
    // DOWNLOADS
    {
        name: NEW_DOWNLOAD,
        path: "/downloads/new",
        component: NewDownload,
        helpPage: 48,
    },
    // HOME (default)
    {
        name: HOME,
        path: "/",
        component: Home,
        helpPage: 1,
    },
    // PDF VIEW
    {
        name: PDF_VIEW,
        path: "/codebooks/:pdf#toolbar=0&page=:pagenum",
        component: null,
    },
];
