import { useCallback, useState } from "react";
import { toast } from "react-toastify";
//import PropTypes from "prop-types";

import { VariablesTable } from "./";
import { Button } from "../Buttons";
import { TextInput } from "../FormElements";
import { Icon, LoadingWrapper } from "../GeneralUI";
import api from '../../utils/api';
import { useAppState } from "../../hooks";

export const VariablesSearch = ({ handleClose, noTitle, hide, variableActions, allowedTypes }) => {

    const [ results, setResults ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ searchString, setSearchString ] = useState('')
    const { errorCheckText } = useAppState();

    // massage and submit search query when form is submitted
    const executeSearch = useCallback(() => {
        const payload = {};
        setLoading(true)

        payload.searchstring = searchString;
        payload.searchtype = "question";

        api.getVariablesBySearch(payload)
            .then(res => {
                if (res.data) {
                    if (allowedTypes) {
                        setResults(res.data.filter((variable) => allowedTypes.indexOf(variable.data_type) > -1))
                    } else {
                        setResults(res.data);
                    }
                }
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Variables.'))
                setResults([]);
            })
            .finally(() => {
                setLoading(false)
            })
    }, [searchString, errorCheckText, allowedTypes])

    
    // submit the search
    const handleSubmit = e => {
        e.preventDefault();
        
        executeSearch();
            
    }

    const handleClear = e => {
        e.preventDefault();
        setResults([]);
        setSearchString('')
    }
    return (
        <section className={hide ? 'd-none' : ''}>

            <div className="d-flex align-items-center mb-3">
                {!noTitle && <h3 className="h5 mb-0 mr-3">Search Variables</h3> }
                {handleClose && <Button variant="primary" size="xs" onClick={handleClose}>Close</Button> }
            </div>
            
            <form onSubmit={handleSubmit}>

                <div className="row">

                    {/* Main search input */}
                    <div className="col-12 col-lg-9 py-2 py-lg-0">  

                        <TextInput 
                            name="searchString"
                            value={searchString}
                            onChange={setSearchString}
                            label="Search by Keyword or Variable Name"
                        />

                    </div>


                    {/* Buttons */}
                    <div className="d-flex col-12 col-md-8 col-lg-3 py-2 py-lg-0 align-items-center" style={{marginTop: 42}}>

                        <Button 
                            size="xs" 
                            variant="primary" 
                            classes="font-weight-600"
                            uppercase
                            type="reset"
                            onClick={handleClear}
                        >
                            Clear Search
                        </Button>

                        <span className="p-1"></span>

                        <Button 
                            size="md" 
                            variant="secondary"
                            classes="font-weight-600 flex-grow-1"
                            uppercase
                            type="submit"
                            disabled={loading}
                        >
                            Search <Icon name="search" />
                        </Button>

                    </div>

                </div>

            </form>

            <h3 className="h6 mt-5 mb-0">Search Results</h3>
            
            {/* Variable results */}
            <LoadingWrapper loading={loading}>
            
                <VariablesTable 
                    id="variables-search-table"
                    /*scopelimit={scopeSent}*/
                    variableActions={variableActions} 
                    variables={results} 
                />

            </LoadingWrapper>

        </section>
    )
}

/*VariablesSearch.propTypes = {
    handleClose: PropTypes.func,
    noTitle: PropTypes.bool,
    hide: PropTypes.bool,
    variableActions: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.object,  PropTypes.func])
    ),
}*/
