import { useEffect, useState, useCallback } from "react"
import { Link, useParams } from "react-router-dom"

import { InnerPage } from "../../layout"
import { Alert, Icon, Spacer } from '../../components/GeneralUI'
import { Button, IconButton } from '../../components/Buttons'
import { /* CopyConstructButton,*/ DeleteConstructButton } from "../../components/ClinicalConstructs";
import { VariableDetailsTable, VariablesSelect, VariableFieldEditForm } from '../../components/Variables';
import { CohortSelection } from "../../components/Cohorts"

import api from '../../utils/api'
import { toast } from "react-toastify"
import { getPathFromRouteName } from "../../utils"
import { useAppState/*, useCancelAsyncCall*/ } from "../../hooks"
import { ALL_VARIABLES } from "../../utils/constants"

const PageActions = ({variable = {}, handleCompareVars, userCanEdit, editing, setEditing}) => (

    <div className="d-flex align-items-center">
        
        {variable.variable_type !== "variable" ?
                <>
                <DeleteConstructButton
                    construct={variable}
                    returnRoute={ALL_VARIABLES}
                />
                {/* Removing for now per issue #254
                <CopyConstructButton
                    construct={variable}
                />
                */}
                </>
        :
                userCanEdit &&
                    <IconButton
                        onClick={() => setEditing(!editing)}
                        iconName="pencil-alt" 
                        //iconWeight={editing ? 700 : 400}
                        tooltip="Edit Variable Fields"
                        variant="outline"
                    />
        }

        <Button variant="secondary" classes="position-static" onClick={handleCompareVars}>
            Compare Variables <Icon name="plus-circle"></Icon>
        </Button>
    
    </div>

)


export const ViewVariable = () => {

    const { id } = useParams();
    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText, userPrivs } = useAppState();

    const [ varIdList, setVarIdList ] = useState([id]);
    const [ selectedCohort, setSelectedCohort ] = useState(1);
    const [ choosingVariable, setChoosingVariable ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const [ countsLoading, setCountsLoading ] = useState(false);
    const [ editing, setEditing ] = useState(false);
    const [ variables, setVariables]  = useState([]);
    const userCanEdit = userPrivs.includes('admin');
    
    useEffect(() => {
        setCountsLoading(true)
        api.getVariableByName(varIdList, selectedCohort)
            .then(res => {
                //if (cancelAsyncCall()) return null;
                // use of toSorted here is because it's important we make sure
                // our returned variables are in the same order as our
                // varIdList (otherwise the editing form will go wonky)
                setVariables(res.data.toSorted((a, b) => {
                    return (varIdList.indexOf(a.variable_name) > varIdList.indexOf(b.variable_name)) ? 1 : -1;
                    })
                )
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to get Variable.'));
            })
            .finally(() => {
                setLoading(false)
                setCountsLoading(false)
            })

    }, [/*cancelAsyncCall, */varIdList, selectedCohort, errorCheckText])

    const handleCompareVars = () => {
        // toggle comparison form on and off
        setChoosingVariable(!choosingVariable)
    }

    const addVariable = (variable)  => {
        const newVars = [...varIdList]
        newVars.push(variable.variable_name)
        setVarIdList(newVars)
    }

    const removeVariable = (variable)  => {
        const newVars = [...varIdList]
        newVars.pop(variable.variable_name)
        setVarIdList(newVars)
    }
    
    const onEditSubmit = useCallback((varfields) => {
        api.editVariableFields(varfields)
            .then(res => {
                toast.success("Variable edits queued for review.")
            })
        .catch(err => {
            toast.error(errorCheckText(err, 'Unable to edit Variable.'));
        })
        .finally(() => setEditing(false))
    }, [ setEditing, errorCheckText ])
    
    if (!variables || variables.length === 0 ) {
        return (
            <InnerPage
                title="Variable Details"
                intro="Extensive details for your selected variable(s) can be found here. You can add additional variables and view those details side-by-side (click on the Compare Variables button above)."
                loading={loading}
            >

                <Alert variant="warning">
                    No variable was found with the name "{id}."
                    <Spacer size={2} />
                    <Link to={getPathFromRouteName(ALL_VARIABLES)}>Browse Variables Catalog</Link>
                </Alert>

            </InnerPage>
        )
    }

    return (

        <InnerPage
            sticky
            title={"Details for " + variables[0].variable_name}
            intro="Extensive details for your selected variable(s) can be found here. You can add additional variables and view those details side-by-side (click on the Compare Variables button above)."
            loading={loading}
            actions={!variables || variables.length === 0 ? null :
                <PageActions 
                    variable={variables[0]}
                    handleCompareVars={handleCompareVars}
                    userCanEdit={userCanEdit}
                    editing={editing}
                    setEditing={userCanEdit ? setEditing : undefined}
                />
            }
        >

            { editing && 
                <>
                <hr />
                <h3>Edit Variable Fields</h3>
                </>
            }
            
            <VariableFieldEditForm
                variable={variables[0]}
                hide={!editing}
                onReset={() => ''}
                onSubmit={onEditSubmit}
            />
            
            { editing && 
                <Spacer size={2} />
            }


            { choosingVariable && 
                <hr />
            }


            <VariablesSelect 
                hide={!choosingVariable}  // just hide this when a variable is chosen; don't unmount, so options remain selected
                variableActions={[
                    (variable) => {
                        // if variable is in the selected ones, the button will remove it
                        if (variables.findIndex(v => v.variable_name === variable.variable_name) > -1) {
                            return {
                                onClick: removeVariable,
                                tooltip: 'Remove from Comparison',
                                iconName: 'check-square',
                                variant: 'secondary',
                                disabled: countsLoading
                            } 
                        }
                        // otherwise, the button will add it
                        return {
                            onClick: addVariable,
                            tooltip: 'Select for Comparison',
                            iconName: 'square',
                            iconWeight: 400,
                            variant: 'secondary',
                            disabled: countsLoading
                        }
                    }
                ]} 
                handleClose={() => setChoosingVariable(false)}
            />
        
            {/* Cohort Selection for counts */}
            <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-end">
                    
                    <span className="mr-2 font-italic">Show counts for </span>
                    
                    <CohortSelection 
                        classes="w-auto"
                        name="cohort"
                        onChange={setSelectedCohort}
                        value={selectedCohort}
                    />

                </div>
            </div>

            <Spacer size={2} />

            {/* Table */}
            <VariableDetailsTable 
                variables={variables}
                removeVariable={removeVariable}
                countsLoading={countsLoading}
            />


        </InnerPage>
    )
}
