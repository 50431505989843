import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from '../../components/Buttons';
import { Icon } from '../../components/GeneralUI';
import { VariablesSelect } from '../../components/Variables';
import { CreateCohortPage } from "../../layout"
import { ConstrainVariable } from '../../components/Constraints';

import { getPathFromRouteName } from "../../utils";
import api from "../../utils/api";
import { VIEW_COHORT, VARIABLE_TYPES } from '../../utils/constants';
import { useAppState } from "../../hooks";

const initialResults = []

export const AscertainPedigrees = () => {

    const [ variable, setVariable ] = useState(null)
    const [ results, setResults ] = useState(initialResults)
    const [ name, setName ] = useState('')
    const [ description, setDescription ] = useState('')
    const [ constraint, setConstraint ] = useState(null)
    const [ previewLoading, setPreviewLoading ] = useState(false)
    const [ cohortSaving, setCohortSaving ] = useState(false)
    const [ furtherConstraint, setFurtherConstraint ] = useState(false);
    
    const { id } = useParams();
    const navigate = useNavigate();
    const { errorCheckText } = useAppState();

    // If the variable gets deleted (e.g. to choose a new one) reset results 
    useEffect(() => {
        if (!variable) {
            setResults(initialResults)
        }
    }, [variable])

    const variableActions = [
        {
            onClick: setVariable,
            tooltip: 'Select and constrain',
            iconName: 'plus-circle',
            variant: 'secondary'
        }
    ]

    const handlePreviewResults = (constraint) => {
        // set this now so it can be used when saving (passed to CreateCohortPage)
        setConstraint(constraint)
        setPreviewLoading(true)

        api.previewCohort({
                parent_id: id,
                variable: (furtherConstraint && variable) ? variable.variable_name : undefined,
                ...constraint // constraint_value/constraint_values and cohort_type
            })
            .then(res => {
                setResults(res.data);
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to preview Pedigree Set.'))
            })
            .finally(() => {
                setPreviewLoading(false)
            })
    }
    
    const handleIncludeAll = () => {
        // Only one constraint - the preview type.
        setFurtherConstraint(false);
        handlePreviewResults({
            cohort_type: 'PEDIGREE'
        });
    }

    const handleSave = () => {
        setCohortSaving(true);
        
        api.createCohort({
                parent_id: id,
                name,
                description,
                variable: (furtherConstraint && variable) ? variable.variable_name : undefined,
                ...constraint // constraint_value/constraint_values and cohort_type
            })
            .then(res => {
                const id = res.data.new_cohort_id;
                setCohortSaving(false);
                navigate(getPathFromRouteName(VIEW_COHORT, {id}))
            })
            .catch(err => {
                toast.error(errorCheckText(err, 'Unable to save Pedigree Set.'))
                setCohortSaving(false);
            })

    }

    return (
        <CreateCohortPage
            title="Ascertain Pedigrees"
            intro='Ascertain Pedigrees in DIVER involves creating an "ascertained cohort" from an existing "index cases cohort" by adding related individuals, potentially based on simple ascertainment constraints for those related individuals.'
            formProps={{
                parentId : id,
                name,
                description,
                setName,
                setDescription,
            }}
            onSave={handleSave}
            recordsCondition={!!constraint}
            results={results}
            previewLoading={previewLoading}
            cohortSaving={cohortSaving}
            isPedigreeForm
        >
            
            <div className="d-flex my-4 mb-2">
                <Button
                    variant="secondary"
                    onClick={handleIncludeAll}
                >
                    Include all relatives of everyone in my index cohort
                </Button>
            </div>
            
             <p>-- OR --</p>
            
            { furtherConstraint ? 
                <VariablesSelect
                    variableActions={variableActions} 
                    hide={!!variable} // just hide this when a variable is chosen; don't unmount, so options remain selected
                    allowedTypes={[VARIABLE_TYPES.CATEGORICAL, VARIABLE_TYPES.DICHOTOMOUS, ]}
                />
                :
                // "Constrain Further" Button
                <div className="d-flex my-4 mb-2">

                    <Button
                        variant="secondary"
                        onClick={() => { setFurtherConstraint(true)}}
                    >
                        <span>Define Ascertainment Criteria <Icon name="plus-circle" /></span>
                    </Button>
                
                </div>
            
            }
            {
                (!!variable && furtherConstraint) &&
           
                    <ConstrainVariable 
                        variable={variable} 
                        deleteVariable={() => setVariable(null)} 
                        onSubmit={handlePreviewResults} 
                        submitLoading={previewLoading}
                        isPedigreeForm
                    />
            }
         
        </CreateCohortPage>
    )
}
