import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Count } from '../../components/Typography';
import { Button, IconButton } from '../../components/Buttons';
import { CohortForm, CohortPreviewSummaries, DeleteCohortButton } from '../../components/Cohorts';
import { Alert, Icon, Spacer } from '../../components/GeneralUI';
import { InnerPage } from '../../layout';
import api from '../../utils/api';
import { 
    getPlainLangVariableConstraint, 
    getPlainLangRelationConstraint, 
    getPlainLangPedigreeConstraint, 
    getCohortType, 
    getPathFromRouteName
} from '../../utils';
import { ALL_COHORTS, COHORT_TYPES } from '../../utils/constants';
import { useAppState/*, useCancelAsyncCall*/ } from '../../hooks';


const renderCohortActions = (cohort) => {
    const id = cohort.cohortid;

    const cohortActions = [
        /* Removing for now per issue #251
        {
            name: 'logic',
            to: `/cohorts/logic/${id}`,
            tooltip: 'View Logic',
            iconName: 'expanded-detail',
            fontAwesome: false,
        },*/
        {
            name: 'pedigree',
            to: `/cohorts/pedigrees/${id}`,
            tooltip: 'Ascertain Pedigrees',
            iconName: 'pedigree',
            fontAwesome: false,
        },
        {
            name: 'compound',
            to: `/cohorts/compound/${id}`,
            tooltip: 'Create Compound Set',
            iconName: 'combine-cohort',
            fontAwesome: false,
        },
        {
            name: 'subset',
            to: `/cohorts/subset/${id}`,
            tooltip: 'Create Subset',
            iconName: 'plus-circle'
        },
        
    ]

    return (
        
        <div className="icon-btn-group">

            {/* Don't show delete for "All individuals" cohort */}
            {cohort.cohortid !== 1 && 
                <DeleteCohortButton
                    cohort={cohort}
                    returnRoute={ALL_COHORTS}
                />
            }

            {cohortActions.map((action, i) => (
                <IconButton
                    key={i} 
                    {...action}
                    variant={action.variant ? action.variant : "outline"}
                />
            ))}
        </div>

    )
}

const renderConstraint = (cohort) => {
    const cohortType = getCohortType(cohort);

    // No constraint for "all individuals"
    if (!cohort.constraint_type) {
        return <div>None</div>
    }

    switch (cohortType) {
        case COHORT_TYPES.SUBSET: 
            return <div>{cohort.variable_name} {getPlainLangVariableConstraint(cohort.constraint_type, cohort.constraint_values)}</div>
        case COHORT_TYPES.COMPOUND: 
            return <div>{getPlainLangRelationConstraint(cohort.constraint_type, cohort.constraint_values)}</div>
        case COHORT_TYPES.PEDIGREES:
            return <div>{getPlainLangPedigreeConstraint(cohort.variable_name, cohort.constraint_values)}</div>       
        default:
            return <Alert>Unknown. The type of this Cohort is unrecognized.</Alert>
    }
   
}

export const ViewCohort = () => {

    const [ results, setResults ] = useState([])
    const [ cohort, setCohort ] = useState({});
    const [ editing, setEditing ] = useState(false);
    const [ name, setName ] = useState('')
    const [ description, setDescription ] = useState('')

    const [ loading, setLoading ] = useState(true)

    const { id } = useParams();
    //const cancelAsyncCall = useCancelAsyncCall();
    const { errorCheckText } = useAppState();

    // Update state if we go to a new cohort
    useEffect(() => {
        setName(cohort.name);
        setDescription(cohort.description)
    }, [cohort])


    // Get the cohort details and records
    useEffect(() => {

        const getCohortData = async () => {
            try {

                const cohortResponse = await api.getCohort(id);

                //if (cancelAsyncCall()) return null;

                setCohort(cohortResponse.data.cohorts[0]);

                const cohortSummaryResponse = await api.getCohortSummary(id);
                
                //if (cancelAsyncCall()) return null;

                setResults(cohortSummaryResponse.data);

            } catch (err) {
                toast.error(errorCheckText(err, `Could not get Cohort with id ${id}.`))
            } finally {
                setLoading(false)
            }
        }

        getCohortData();
        
    }, [/*cancelAsyncCall, */id, errorCheckText])


    // Submit edit form for name and description
    const handleSubmit = (formValues) => {
        const { description, name } = formValues;

        if (name || description) {
            api.updateCohort({
                    ...formValues,
                    cohortid: cohort.cohortid
                })
                .then(res => {
                    
                    setEditing(false)
                    toast.success('Changes saved.')

                    api.getCohort(id)
                        .then(res => {
                            setCohort(res.data.cohorts[0]);
                        })
                        .catch(err => {
                            toast.error(errorCheckText(err, 'Unable to update changes on page. Please reload this window.'))
                        })
                        
                })
                .catch(err => {
                    toast.error(errorCheckText(err, 'Unable to save Cohort.'))
                });
        }
        
    }

    // Reset form for name and description
    const handleReset = () => {
        setEditing(false)
    }


    if ( !cohort || !cohort.name ) {
        return (
            <InnerPage
                title={cohort && cohort.constraint_type === "PEDIGREE" ? "View Pedigree Set" : "View Cohort"}
                loading={loading}
            >

                <Alert variant="warning">

                    No cohort was found with the id "{id}".

                    <Spacer size={2} />

                    <Link to={getPathFromRouteName(ALL_COHORTS)}>Build a Cohort</Link>

                </Alert>

            </InnerPage>
        )
    }


    return (
        
        <InnerPage
            title={cohort.name}
            loading={loading}
            editButton={<Button classes="btn--text-link" onClick={() => setEditing(true)}>Edit <Icon name="pencil-alt" /></Button>}
            actions={renderCohortActions(cohort)}
        >

            {cohort.parent &&
                <p>{cohort.constraint_type === "PEDIGREE" ? "Index Cohort" : "Parent Cohort"}: {cohort.parent}</p>
            }
            {cohort.description &&
                <p>Description: {cohort.description}</p>
            }


            {/* Name/description editing form */}
            { editing &&
                <CohortForm 
                    name={name}
                    description={description}
                    setName={setName}
                    setDescription={setDescription}
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                    disableParentSet
                />
            }

            <Spacer size={3} />
            

            {/* Constraint */}
            <section>

                <h3 className="h5">Constraint</h3>

                {renderConstraint(cohort)}

            
            </section>

            <Spacer size={3} />


            {/* Data/Results */}
            <section>

                <h3 className="h5">Summary Of {cohort && cohort.constraint_type === "PEDIGREE" ? "Pedigrees" : "Individuals"} In Data Set <Count>{
                    results ?
                            cohort.constraint_type !== "PEDIGREE" ?
                                results.reduce(
                                    (total, group) => total + group.count, 0)
                            :
                                results.reduce(
                                    (total, group) => total + group.pedigree_count, 0)
                            : 0}</Count></h3>
                
                <CohortPreviewSummaries 
                    records={results ? results : []} 
                    isPedigreeForm={cohort.constraint_type === "PEDIGREE"}
                    noPedVar={typeof cohort.variable_name === "undefined"}
                />
            
            </section>
        

        </InnerPage>

    )
}
