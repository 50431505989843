import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
//import PropTypes from "prop-types";

import { Button } from "../components/Buttons";
import { Icon, Spacer } from '../components/GeneralUI';
import { VariableCollectionForm, VariablesSelect } from "../components/Variables";
import { ConstructValues } from '../components/ClinicalConstructs';
import { InnerPage } from ".";
import { ClinicalConstructContext } from "../contexts";
import { VIEW_VARIABLE } from "../utils/constants";
import { addItemToArray, getPathFromRouteName, removeItemFromArray, updateItemInArray } from "../utils";
import api from "../utils/api";
import { useAppState } from "../hooks";

const allValuesHaveConstraints = (values, constraints, defaultValue) => (
    values.every((value, i) => {
        //if (parseInt(defaultValue) === i) return true;
        if (value.name === defaultValue) return true;
        // if (value.description === "[UNK]") return true;
        if (value.name === "[UNK]") return true;
        
        if (!constraints[i]) {
            return false;
        } 
        
        if (!constraints[i].constraints || !constraints[i].constraints[0]) {
            return false;
        }
        
        return true;
        
    })
)


/**
 * Holds the data and functionality that is common to all Custom Variable types,
 * i.e. name/description form, variable selection, value naming
 */
export const CreateClinicalConstructPage = ({ allowedTypes, construct_type, children, intro, simple, title, usesDefaultValue, variableLimit, alwaysHaveUnknown }) => {

    const navigate = useNavigate();
    const { errorCheckText } = useAppState();

    const [ name, setName ] = useState("");
    const [ description, setDescription ] = useState("");

    //const [ values, setValues ] = useState(alwaysHaveUnknown ? [{name: "Unknown", description: "[UNK]"},{name: "", description: ""},{name: "", description: ""}] : [{name: "", description: ""},{name: "", description: ""}]);
    const [ values, setValues ] = useState(alwaysHaveUnknown ? [{name: "[UNK]", description: "Unknown"},{name: "", description: ""},{name: "", description: ""}] : [{name: "", description: ""},{name: "", description: ""}]);
    const [ constraints, setConstraints ] = useState([{}, {}]);
    const [ defaultValue, setDefaultValue ] = useState(null);

    const [ variables, setVariables ] = useState([]);
    
    const [ saveEnabled, setSaveEnabled ] = useState(false);
    const [ constructSaving, setConstructSaving ] = useState(false);
    const [ showVariableSearch, setShowVariableSearch ] = useState(false);

    const clinicalConstructContextValue = { 
        defaultValue,
        variables, 
        values, 
        setValues,
        setConstraints,
        setDefaultValue, // only for downcode variable
    };

    
    // update save button: disabled or enabled
    useEffect(() => {

        if (
            !name 
            || (variableLimit && variables.length < variableLimit)
            || (usesDefaultValue && (defaultValue === null || typeof defaultValue === "undefined"))
            || values.filter(v => v.name.trim()).length < 2
            || !allValuesHaveConstraints(values, constraints, defaultValue)
        ) {
            setSaveEnabled(false);
            return;
        }

        setSaveEnabled(true);

    }, [ constraints, defaultValue, name, usesDefaultValue, values, variableLimit, variables ])

    const meetsOrExceedsVariableLimit = (vars) => variableLimit && vars.length >= variableLimit;

    const addVariableToConstruct = (variable) => {

        if (meetsOrExceedsVariableLimit(variables)) {
            return;
        }

        const newVars = [...variables];
        newVars.push(variable);
        setVariables(newVars);

        if (meetsOrExceedsVariableLimit(newVars)) {
            setShowVariableSearch(false);
        }

    }

    const removeVariableFromConstruct = (variable) => {
        const newVars = [...variables];
        const foundIndex = newVars.findIndex(v => v.variable_name === variable.variable_name);
        newVars.splice(foundIndex, 1);

        if (variableLimit || !newVars.length) {
            setShowVariableSearch(true);
        }

        setVariables(newVars);
    }

    const handleHideSearch = () => setShowVariableSearch(false);
    const handleShowSearch = () => setShowVariableSearch(true);


    const variableSearchResActions = [
        (variable) => {
            // if variable is in the selected ones, the button will remove it
            if (variables.findIndex(v => v.variable_name === variable.variable_name) > -1) {
                return {
                    onClick: removeVariableFromConstruct,
                    tooltip:  'Remove from Custom Variable',
                    iconName: 'check-square',
                    variant: 'secondary'
                } 
            }
            // otherwise, the button will add it
            return {
                onClick: addVariableToConstruct,
                tooltip: 'Add to Custom Variable',
                iconName: 'square',
                iconWeight: 400,
                variant: 'secondary',
            }
        }
    ]

    const variableCollectionActions = [
        {
            onClick: removeVariableFromConstruct,
            tooltip: 'Remove from Custom Variable',
            iconName: 'check-square',
            variant: 'secondary',
        }
    ]

    const handleValueNameChange = useCallback((value, i) => {
        const newVal = {
            name: value,
            description: values[i].description
        }
        updateItemInArray(newVal, i, values, setValues)
    }, [values])


    const handleValueDescriptionChange = useCallback((value, i) => {
        const newVal = {
            name: values[i].name,
            description: value,
        }
        updateItemInArray(newVal, i, values, setValues)
    }, [values])


    const handleRemoveValue = useCallback((i) => {
        if (values.length <= 2) return;

        removeItemFromArray(i, values, setValues)
        removeItemFromArray(i, constraints, setConstraints)
    }, [values,constraints])

    const handleAddValue = useCallback(() => {
        // Not sure why this limit is here. Removing it.
        //if (values.length >= 8) return;

        addItemToArray(
            { name : "", description: "" }, values, setValues
        );
        addItemToArray({}, constraints, setConstraints);

    }, [values,constraints])

    const handleSave = () => {
        setConstructSaving(true)

        const mappedValues = constraints.map((c, i) => ({
            definition: c,
            ...values[i], // name & description
        }))
        
        /*if (alwaysHaveUnknown) {
            mappedValues.forEach((val, idx) => {
                // swap these back for submission
                if (val.description === "[UNK]") {
                    mappedValues[idx].name = "[UNK]"
                    mappedValues[idx].description = "Unknown"
                }
            })
        }*/

        const payload = {
            name,
            construct_type,
            defaultValue,
            description,
            variables: variables.map(v => v.variable_name),
            variable_limit: variableLimit,
            values: mappedValues,
        }


        api.createConstruct(payload)
            .then((res) => {
                setConstructSaving(false);
                navigate(getPathFromRouteName(VIEW_VARIABLE, {id: name}));
            })
            .catch((err) => {
                toast.error(errorCheckText(err, 'Failed to save Custom Variable.'))
                setConstructSaving(false);
            })
    }

    return (

        <InnerPage
            title={title}
            intro={intro}
            sticky
            actions={
                <Button
                    variant="secondary"
                    disabled={!saveEnabled}
                    onClick={handleSave}
                    buttonLoading={constructSaving}
                >
                    Save Custom Variable <Icon name="check-circle" />
                </Button>
            }
        >
            <ClinicalConstructContext.Provider value={clinicalConstructContextValue}>

                <VariableCollectionForm 
                    name={name}
                    setName={setName}
                    nameType="variable_name"
                    description={description}
                    setDescription={setDescription}
                    componentLabel="Custom Variable"
                    variablesLabel={ variableLimit === 1 ?
                         "Variable to Downcode" 
                        :
                          "Variables included in this Custom Variable"
                    }
                    variables={variables}
                    variableActions={variableCollectionActions}
                />
                                
                <Spacer size={3} />

                {
                    showVariableSearch ? 
                        <Button 
                            size="md" 
                            variant="secondary"
                            classes="font-weight-600 mb-3"
                            uppercase
                            onClick={handleHideSearch}
                        >
                            Hide Variable Search <Icon name="eye-slash" />
                        </Button>
                    :
                        (variables.length < variableLimit || !variableLimit) &&
                            <Button
                                size="md" 
                                variant="secondary"
                                classes="font-weight-600"
                                uppercase
                                onClick={handleShowSearch}
                            >
                                {variableLimit === 1 ? "Choose Variable" : "Add Variables"} <Icon name="plus-circle" />
                            </Button>
                }

                <VariablesSelect
                    variableActions={variableSearchResActions} 
                    hide={!showVariableSearch} // just hide this when a variable is chosen; don't unmount, so options remain selected
                    allowedTypes={allowedTypes}
                />

                
                { ((variableLimit && variables.length >= variableLimit) 
                    || (!variableLimit && variables.length >= 1) )
                    && variables.filter(v => v.data_type).length > 0 // Can be deleted later. Assume all variables will have a dataType
                    &&
                    <>
                        <h3 className="h5 mt-5">Create New Values</h3>

                        <ConstructValues
                            simple={simple}
                            handleRemoveValue={handleRemoveValue}
                            handleValueNameChange={handleValueNameChange}
                            handleValueDescriptionChange={handleValueDescriptionChange}
                            handleAddValue={handleAddValue}
                            alwaysHaveUnknown={alwaysHaveUnknown}
                        />
                    </>
                }

                <Spacer size={3} />

                {children}

            </ClinicalConstructContext.Provider>

        </InnerPage>

    )
}


/*CreateClinicalConstructPage.propTypes = {
    allowedTypes: PropTypes.arrayOf(PropTypes.string),
    construct_type: PropTypes.string,
    children: PropTypes.node,
    intro: PropTypes.string,
    simple: PropTypes.bool,
    title: PropTypes.string.isRequired,
    usesDefaultValue: PropTypes.bool,
    variableLimit: PropTypes.number,
}*/
