import { useCallback, useState } from "react"
//import PropTypes from "prop-types"

import { CONSTRAINT_TYPES, VARIABLE_TYPE_CONSTRAINTS } from "../../utils/constants/constraints"
import { Button, IconButton } from "../Buttons"
import { /*CheckboxInput*/ Select, NumberInput } from "../FormElements"
import { Icon } from "../GeneralUI"
import { ConstraintForm } from "./"
//import { variableShape } from "../../utils"

/**
 * Constraint form, along with a few extras like the variable that is being constrained
 */
export const ConstrainVariable = ({ deleteVariable, onSubmit : _handleSubmit, variable, submitLoading, isPedigreeForm }) => {

    
    // alert('about to set constraintType using ' + variable + ' with data_type ' + variable.data_type + '...')
    // alert('giving ' + VARIABLE_TYPE_CONSTRAINTS[variable.data_type][0])
    const [ constraintType, setConstraintType ] = useState(
            VARIABLE_TYPE_CONSTRAINTS[variable.data_type][0]);
            // how the variable is constrained, e.g. "EQ" or "BETWEEN"
    const [ constraintValue, setConstraintValue ] = useState();
            // the value for the constraint e.g. "hearing loss" or "3"
    //const [ unrelatedOnly, setUnrelatedOnly ] = useState(false)
    const [ minPedSize, setMinPedSize ] = useState(0);
    const [ pedConstraint, setPedConstraint ] = useState("min");
    const [ pedConstraintCount, setPedConstraintCount ] = useState(0);

    const handleSubmit = useCallback((e) => {

        e.preventDefault();

        /**
         * @todo
         * API/BE support for "include unrelated only"
         */
        const submitData = {
            //unrelated_only: unrelatedOnly,
            cohort_type: constraintType,
        }

        const { valProp, regex, nregex } = CONSTRAINT_TYPES[constraintType];
        submitData[valProp] = constraintValue;

        if (regex) {
            submitData[valProp] = regex.replace('value', submitData[valProp]);
            submitData.cohort_type = 'REGEX';
        }

        if (nregex) {
            submitData[valProp] = nregex.replace('value', submitData[valProp]);
            submitData.cohort_type = 'NREGEX';
        }
        
        if (isPedigreeForm) {
            submitData.cohort_type = "PEDIGREE";
            submitData.min_pedsize = minPedSize;
            submitData.ped_constraint = pedConstraint;
            submitData.ped_constraint_count = pedConstraintCount;
        }


        _handleSubmit(submitData)
      
    }, [
        isPedigreeForm,
        constraintType,
        constraintValue,
        minPedSize,
        pedConstraint,
        pedConstraintCount,
        _handleSubmit,
        //unrelatedOnly
    ])

    
    //const toggleUnrelatedOnly = useCallback(() => {
    //    setUnrelatedOnly(!unrelatedOnly)
    //}, [unrelatedOnly])

    return (
        <section>

            <form onSubmit={handleSubmit}>

                <h3 className="h5">
                    {isPedigreeForm ? "Constrain Variable for Pedigree Ascertainment" :
                            "Define Filter Criteria for Selected Variable"}</h3>

                <div className="row">

                    <div className="col-12 col-sm-6 col-md-3 align-items-start d-flex"> 
                        <span className="form-control d-flex align-items-center">
                            {variable.variable_name}
                            <span className="ml-auto">
                                <IconButton 
                                    classes="btn--icon bg-transparent p-0" 
                                    onClick={deleteVariable}
                                    tooltip="Deselect Variable"
                                    iconName="trash-alt"
                                />
                            </span>
                        </span>
                    </div>
   
                    <ConstraintForm 
                        constraintType={constraintType}
                        setConstraintType={setConstraintType}
                        setConstraintValue={setConstraintValue}
                        variable={variable}
                        isPedigreeForm={isPedigreeForm}
                    />
                </div>
                
                {isPedigreeForm &&
                    <>
                    <div className="row">
                        
                        <div className="col-12 col-sm-6 col-md-6 align-items-end">
                            <Select
                                label="Apply ascertainment criteria to this many pedigree members"
                                name="pedconstraint"
                                value={pedConstraint}
                                onChange={setPedConstraint}
                                options={[
                                    {
                                        name: "At least",
                                        value: "min",
                                    },
                                    {
                                        name: "At most",
                                        value: "max",
                                    },
                                    {
                                        name: "Exactly",
                                        value: "equal",
                                    }
                                ]}
                            />
                        </div>
                        
                        <div className="col-12 col-sm-6 col-md-3 align-items-end" style={{"minHeight": "3rem"}}> {/* min-height needed because otherwise in small windows (phones) the input component will render on top of the Select above, which we Do Not Want */}
                            <NumberInput 
                                label="Constraint Count"
                                hideLabel
                                name="pedconstraintcount"
                                value={pedConstraintCount}
                                onChange={setPedConstraintCount}
                                classes="position-absolute"
                                style={{bottom: 0}}
                            />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 col-sm-6 col-md-3">
                            <NumberInput 
                                label="Minimum Pedigree Size"
                                name="minpedsize"
                                value={minPedSize}
                                onChange={setMinPedSize}
                                classes="d-flex flex-grow-1"
                            />
                        </div>
                    
                    </div>
                    </>}

                {/* NOTE: UnrelatedOnly isn't implemented and may never be, so removing this from user view for now. See: MathematicalMedicine/diver-issues#45 */}
                {/*<CheckboxInput
                    name="unrelatedOnly"
                    value={"unrelatedOnly"}
                    checked={unrelatedOnly}
                    onChange={toggleUnrelatedOnly}
                    label="Include ONLY unrelated individuals"
                />*/}

                <div className="row">

                    <div className="d-flex col-12 col-sm-6 col-md-3 align-items-center pt-4">

                        <Button 
                            size="md" 
                            variant="secondary"
                            classes="font-weight-600 flex-grow-1"
                            uppercase
                            type="submit"
                            disabled={submitLoading}
                        >
                            Apply <Icon name="check-circle" />
                        </Button>


                    </div>

                </div>

            </form>

        </section>
    )
}

/*ConstrainVariable.propTypes = {
    deleteVariable: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    variable: PropTypes.shape(variableShape).isRequired,
    submitLoading: PropTypes.bool,
    isPedigreeForm: PropTypes.bool,
}*/
