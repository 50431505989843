import { DowncodeTable } from "../../components/ClinicalConstructs";

import { CreateClinicalConstructPage } from "../../layout";
import { VARIABLE_TYPES } from '../../utils/constants';

export const NewDowncodeConstruct = () => {
    const variableLimit= 1;

    return (

        <CreateClinicalConstructPage
            title="Downcode a Variable"
            intro="Creating a new Custom Variable via Downcoding is done by selecting the variable to downcode, specifying what values your new variable should have, and then indicating under what circumstances values in the original variable should dictate values in the new variable."
            construct_type="downcode"
            simple
            usesDefaultValue
            alwaysHaveUnknown
            variableLimit={variableLimit}
            // all types EXCEPT Dichotomous allowed
            allowedTypes={Object.values(VARIABLE_TYPES).filter(
                    e => e !== VARIABLE_TYPES.DICHOTOMOUS)}
        >

            <DowncodeTable  />     

        </CreateClinicalConstructPage>

    )
}
